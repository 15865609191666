/* eslint-disable react/react-in-jsx-scope */

import { Box, Grid, TextField, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

/* eslint-disable react/prop-types */
function ThirdForm(props) {
    const isEnglish = useSelector((state) => state.lang.value);
    const inputNames = [
        "problemTackledByCompany",
        "Solution",
        "secretTechno",
        "businessModel",
        "Traction",
        "Turnover",
    ]
    const formLabelsen = [
        "Problem tackled by the company",
        "Solution",
        "Secret sauce/techno",
        "Business model",
        "Traction",
        "Turnover"
    ]
    const formLabelsfr = [
        "Problème taclé par l’entreprise",
        "Solution",
        "Secret sauce/techno",
        "Business model",
        "Traction",
        "Chiffre d’affaires"
    ]
    const formik = props.formik;
    const heading = props.heading
    return (
        <Box>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                {" "}
                {heading}
            </Typography>

            <Grid spacing={4}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[0]}
                        label={isEnglish ? formLabelsen[0] : formLabelsfr[0]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.problemTackledByCompany}
                        helperText={(formik.errors.problemTackledByCompany && formik.touched.problemTackledByCompany) ? (formik.errors.problemTackledByCompany) : null}
                        error={(formik.errors.problemTackledByCompany && formik.touched.problemTackledByCompany)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid spacing={6}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[1]}
                        label={isEnglish ? formLabelsen[1] : formLabelsfr[1]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.Solution}
                        helperText={(formik.errors.Solution && formik.touched.Solution) ? (formik.errors.Solution) : null}
                        error={(formik.errors.Solution && formik.touched.Solution)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid spacing={6}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[2]}
                        label={isEnglish ? formLabelsen[2] : formLabelsfr[2]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.secretTechno}
                        helperText={(formik.errors.secretTechno && formik.touched.secretTechno) ? (formik.errors.secretTechno) : null}
                        error={(formik.errors.secretTechno && formik.touched.secretTechno)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid spacing={6}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[3]}
                        label={isEnglish ? formLabelsen[3] : formLabelsfr[3]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.businessModel}
                        helperText={(formik.errors.businessModel && formik.touched.businessModel) ? (formik.errors.businessModel) : null}
                        error={(formik.errors.businessModel && formik.touched.businessModel)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid spacing={6}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[4]}
                        label={isEnglish ? formLabelsen[4] : formLabelsfr[4]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.Traction}
                        helperText={(formik.errors.Traction && formik.touched.Traction) ? (formik.errors.Traction) : null}
                        error={(formik.errors.Traction && formik.touched.Traction)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid spacing={6}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[5]}
                        label={isEnglish ? formLabelsen[5] : formLabelsfr[5]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.Turnover}
                        helperText={(formik.errors.Turnover && formik.touched.Turnover) ? (formik.errors.Turnover) : null}
                        error={(formik.errors.Turnover && formik.touched.Turnover)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

ThirdForm.propTypes = {
    formik: PropTypes.object
};
export default ThirdForm