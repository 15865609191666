/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

import { Box, Grid, TextField, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";


function FirstForm(props) {
    const isEnglish = useSelector((state) => state.lang.value);
    const inputNames = [
        "companyName",
        "activityArea",
        "pays",
        "creationDate",
        "lastFundraisingDate",
        "lastFundraisingAmount",
        "need",
    ]
    const formLabelsen = [
        "Company Name",
        "Activity area",
        "Pays",
        "Creation date",
        "Date of the last fundraising",
        "Last fundraising amount",
        "Need (size and usage)"
    ]
    const formLabelsfr =
        ["Nom de l’entreprise",
            "Secteur d’activité",
            "Pays",
            "Date de création",
            "Date de la dernière levée de fonds",
            "Montant de la dernière levée de fonds",
            "Besoin (taille de la levée et usages)"]
    // const isRequired = false
    const formik = props.formik;
    const heading = props.heading
    return (
        <Box>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                {" "}
                {heading}
            </Typography>

            <Grid container spacing={6}>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[0]}
                        label={isEnglish ? formLabelsen[0] : formLabelsfr[0]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.companyName}
                        helperText={(formik.errors.companyName && formik.touched.companyName) ? (formik.errors.companyName) : null}
                        error={(formik.errors.companyName && formik.touched.companyName)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[1]}
                        label={isEnglish ? formLabelsen[1] : formLabelsfr[1]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.activityArea}
                        helperText={(formik.errors.activityArea && formik.touched.activityArea) ? (formik.errors.activityArea) : null}
                        error={(formik.errors.activityArea && formik.touched.activityArea)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[2]}
                        label={isEnglish ? formLabelsen[2] : formLabelsfr[2]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.pays}
                        helperText={(formik.errors.pays && formik.touched.pays) ? (formik.errors.pays) : null}
                        error={(formik.errors.pays && formik.touched.pays)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[3]}
                        // label={isEnglish ? formLabelsen[3] : formLabelsfr[3]}
                        variant="outlined"
                        type="date"
                        fullWidth
                        margin="normal"
                        value={formik.values.creationDate}
                        helperText={isEnglish ? formLabelsen[3] : formLabelsfr[3]}
                        error={(formik.errors.creationDate && formik.touched.creationDate)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[4]}
                        label={isEnglish ? formLabelsen[4] : formLabelsfr[4]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.lastFundraisingDate}
                        helperText={(formik.errors.lastFundraisingDate && formik.touched.lastFundraisingDate) ? (formik.errors.lastFundraisingDate) : null}
                        error={(formik.errors.lastFundraisingDate && formik.touched.lastFundraisingDate)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[5]}
                        label={isEnglish ? formLabelsen[5] : formLabelsfr[5]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.lastFundraisingAmount}
                        helperText={(formik.errors.lastFundraisingAmount && formik.touched.lastFundraisingAmount) ? (formik.errors.lastFundraisingAmount) : null}
                        error={(formik.errors.lastFundraisingAmount && formik.touched.lastFundraisingAmount)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[6]}
                        label={isEnglish ? formLabelsen[6] : formLabelsfr[6]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.need}
                        helperText={(formik.errors.need && formik.touched.need) ? (formik.errors.need) : null}
                        error={(formik.errors.need && formik.touched.need)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

FirstForm.propTypes = {
    formik: PropTypes.object
};
export default FirstForm;
