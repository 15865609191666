/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Box, Grid, TextField, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

function FifthForm(props) {
    const isEnglish = useSelector((state) => state.lang.value);
    const inputNames = [
        "socialImpact",
        "environmentalImpact",
    ]
    const formLabelsen = [
        "Social impact",
        "Environmental impact"
    ]
    const formLabelsfr = [
        "Impact social",
        "Impact environnemental",
    ]
    const formik = props.formik;
    const heading = props.heading
    return (
        <Box>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                {" "}
                {heading}
            </Typography>

            <Grid spacing={12}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[0]}
                        label={isEnglish ? formLabelsen[0] : formLabelsfr[0]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.socialImpact}
                        helperText={(formik.errors.socialImpact && formik.touched.socialImpact) ? (formik.errors.socialImpact) : null}
                        error={(formik.errors.socialImpact && formik.touched.socialImpact)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid spacing={6}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[1]}
                        label={isEnglish ? formLabelsen[1] : formLabelsfr[1]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.environmentalImpact}
                        helperText={(formik.errors.environmentalImpact && formik.touched.environmentalImpact) ? (formik.errors.environmentalImpact) : null}
                        error={(formik.errors.environmentalImpact && formik.touched.environmentalImpact)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

FifthForm.propTypes = {
    formik: PropTypes.object
};
export default FifthForm