/* eslint-disable no-undef */
import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from '@apollo/client';
import { Box, Button, Container } from "@mui/material";
import TwinHeading from "components/TwinHeading/TwinHeading";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "tss-react/mui";
import { CREATE_USER } from 'graphql/mutation/auth_mutation_gql';
// import { styled } from "@mui/material/styles";
import FirstForm from "./component/FirstForm";
import SecondForm from "./component/SecondForm";
import ThirdForm from "./component/ThirdForm";
import FourthForm from "./component/FourthForm";
import FifthForm from "./component/FifthForm";
import SixthForm from "./component/SixthForm";
// import { ThemeProvider, useTheme } from '@material-ui/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
const useStyles = makeStyles()((theme) => {
    return {
        main: {
            display: "flex",
            justifyContent: "center",
        },

        investorcon: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        btncontainer: {
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
        },
        btn: {
            backgroundColor: "#4cbfd0",
            margin: "2px",
            width: "147px",
            height: "45px",

            "&.MuiButton-root": {
                fontSize: "18px",
                color: "white",
                margin: "5px"

            }
        },
        stepperbox: {
            "& .MuiStepIcon-root": {
                fontSize: "2.5rem",

                [theme.breakpoints.down("lg")]: {
                    fontSize: "2rem",
                },
            },
            "& .MuiStepLabel-label": {
                fontSize: "16px",
                color: "#000000",

                [theme.breakpoints.down("md")]: {
                    fontSize: "12px",

                },
            },
        },
        stepper_con: {
            width: "100%",
        }
    };
});


const signupdata = ["Informations générales", "Equipe", "Activité", "Marché", "Impact", "Documents"];

const stepperComponent = (step, formik) => {
    switch (step) {
        case 0:
            return <FirstForm formik={formik} heading={signupdata[0]} />;
        case 1:
            return <SecondForm formik={formik} heading={signupdata[1]} />;
        case 2:
            return <ThirdForm formik={formik} heading={signupdata[2]} />;
        case 3:
            return <FourthForm formik={formik} heading={signupdata[3]} />;
        case 4:
            return <FifthForm formik={formik} heading={signupdata[4]} />;
        case 5:
            return <SixthForm formik={formik} heading={signupdata[5]} />;
        default:
            return <div>Invalid Step</div>;
    }
};
const validationSchema = yup.object({
    companyName: yup
        .string()
        .min(5)
        .required("Company Name is a required field "),
    activityArea: yup
        .string()
        .required("Activity Area is a required field "),
    pays: yup
        .string()
        .min(0)
        .required("Pays is a required field "),
    creationDate: yup
        .date()
        .required("Creation Date is a required field "),
    lastFundraisingDate: yup
        .date()
        .required("Llast Fundraising Date is a required field "),
    lastFundraisingAmount: yup
        .number()
        .min(0)
        .max(10 ** 8)
        .required("Last Fundraising Amount is a required field "),
    need: yup
        .string()
        .required("Need is a required field "),
    lastName: yup
        .string()
        .min(3)
        .required("Last Name is a required field "),
    firstName: yup
        .string()
        .min(3)
        .required("First Name is a required field "),
    role: yup
        .string()
        .required("Role is a required field "),
    LinkedIn: yup
        .string().url()
        .required("LinkedIn is a required field "),
    problemTackledByCompany: yup
        .string()
        .required("Problem Tackled By Company is a required field "),
    Solution: yup
        .string()
        .required("Solution is a required field "),
    secretTechno: yup
        .string()
        .required("Secret Techno is a required field "),
    businessModel: yup
        .string()
        .required("Business Model is a required field "),
    Traction: yup
        .string()
        .required("Traction is a required field "),
    Turnover: yup
        .number()
        .min(1000)
        .required("Turnover is a required field "),
    marketSize: yup
        .string()
        .required("Market Size is a required field "),
    Competitors: yup
        .string()
        .required("Competitors is a required field "),
    comparativeAdvantages: yup
        .string()
        .required("Comparative Advantages is a required field "),
    "socialImpact": yup
        .string()
        .required("Social Impact is a required field"),
    "environmentalImpact": yup
        .string()
        .required("Environmental Impact is a required field"),
});
const PresentProject = () => {
    const { classes } = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    // const [createUser, { data: userData }] = useMutation(CREATE_USER, {
    //     context: {
    //         uri: process.env.REACT_APP_AUTH_URL
    //     }
    // });
    const formik = useFormik({
        initialValues: {
            "companyName": "",
            "activityArea": "",
            "pays": "",
            "creationDate": "",
            "lastFundraisingDate": "",
            "lastFundraisingAmount": "",
            "need": "",
            "lastName": "",
            "firstName": "",
            "role": "",
            "LinkedIn": "",
            "problemTackledByCompany": "",
            "Solution": "",
            "secretTechno": "",
            "businessModel": "",
            "Traction": "",
            "Turnover": "",
            "marketSize": "",
            "Competitors": "",
            "comparativeAdvantages": "",
            "socialImpact": "",
            "environmentalImpact": ""
        },
        validationSchema: validationSchema,
    });

    const errorInFirst = formik.errors.companyName || formik.errors.activityArea || formik.errors.pays || formik.errors.creationDate || formik.errors.lastFundraisingDate || formik.errors.lastFundraisingAmount || formik.errors.need
    const emptyValuesInFirst = formik.values.companyName || formik.values.activityArea || formik.values.pays || formik.values.creationDate || formik.values.lastFundraisingDate || formik.values.lastFundraisingAmount || formik.values.need
    const errorInSecond = formik.errors.lastName || formik.errors.firstName || formik.errors.role || formik.errors.LinkedIn
    const emptyValuesInSecond = formik.values.lastName || formik.values.firstName || formik.values.role || formik.values.LinkedIn
    const errorInThird = formik.errors.problemTackledByCompany || formik.errors.Solution || formik.errors.secretTechno || formik.errors.businessModel || formik.errors.Traction || formik.errors.Turnover
    const emptyValuesInThird = formik.values.problemTackledByCompany || formik.values.Solution || formik.values.secretTechno || formik.values.businessModel || formik.values.Traction || formik.values.Turnover
    const errorInFourth = formik.errors.marketSize || formik.errors.Competitors || formik.errors.comparativeAdvantages
    const emptyValuesInFourth = formik.values.marketSize || formik.values.Competitors || formik.values.comparativeAdvantages
    const errorInFifth = formik.errors.socialImpact || formik.errors.environmentalImpact
    const emptyValuesInFifth = formik.values.socialImpact || formik.values.environmentalImpact

    // const errorinSecond = [

    // ]

    const handleSubmit = ({ values }) => {
        console.log("user data collected", values)
    };

    const handleNext = () => {
        if (activeStep < 5) {

            if (activeStep === 0 && (errorInFirst || !emptyValuesInFirst)) {
                alert(
                    `You forgot, 🚀 "${errorInFirst}". 🙄😅`
                )
            }
            else if (activeStep === 1 && (errorInSecond || !emptyValuesInSecond)) {
                alert(
                    `You forgot, 🚀 "${errorInSecond}". 🙄😅`
                )
            }
            else if (activeStep === 2 && (errorInThird || !emptyValuesInThird)) {
                alert(
                    `You forgot, 🚀 "${errorInThird}". 🙄😅`
                )
            }
            else if (activeStep === 3 && (errorInFourth || !emptyValuesInFourth)) {
                alert(
                    `You forgot, 🚀 "${errorInFourth}". 🙄😅`
                )
            }
            else if (activeStep === 4 && (errorInFifth || !emptyValuesInFifth)) {
                alert(
                    `You forgot, 🚀 "${errorInFifth}". 🙄😅`
                )
            }
            else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            handleSubmit(formik);
        }
    };

    const handlePrev = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    React.useEffect(() => { }, [activeStep]);

    // React.useEffect(() => {
    //     // console.log(userData);
    //     if (userData?.createUser?.id) {
    //         console.log("User created");
    //     }
    // }, [userData]);
    // const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Container>
            <TwinHeading text="Present your project" />
            <Box className={classes.main}>
                <Box className={classes.investorcon} my={10}>
                    <form onSubmit={formik.handleSubmit}>
                        <Box className={classes.stepperbox}>
                            <Stepper
                                activeStep={activeStep}
                                className={classes.stepperSize}
                            // alternativeLabel={matches ? true : false}
                            >
                                {
                                    signupdata.map((step, i) => (
                                        <Step key={i}>
                                            <StepLabel StepIconProps={{ sx: { fontSize: "2rem" } }}>
                                                {step}
                                            </StepLabel>
                                        </Step>
                                    ))
                                }
                            </Stepper>
                        </Box>

                        <Box className={classes.stepper_con} my={10}>
                            {stepperComponent(activeStep, formik)}
                        </Box>

                        <Box className={classes.btncontainer}>
                            <Box>
                                {activeStep === 0 ? null : (
                                    <Button
                                        variant="contained"
                                        onClick={() => handlePrev()}
                                        className={classes.btn}
                                    >
                                        Back
                                    </Button>
                                )}
                                {
                                    <Button
                                        variant="contained"
                                        type="button"
                                        onClick={() => handleNext()}
                                        className={classes.btn}
                                    >
                                        {activeStep === 5 ? "Submit" : "Next"}
                                    </Button>}
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Container >
    );
};

export default PresentProject