/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

import { Box, Grid, TextField, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

function FourthForm(props) {
    const isEnglish = useSelector((state) => state.lang.value);
    const inputNames = [
        "marketSize",
        "Competitors",
        "comparativeAdvantages",
    ]
    const formLabelsen = [
        "Market size",
        "Competitors",
        "Comparative advantages"
    ]
    const formLabelsfr = [
        "Taille du marché",
        "Concurrents",
        "Avantages comparatifs"
    ]
    const formik = props.formik;
    const heading = props.heading
    return (
        <Box>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                {" "}
                {heading}
            </Typography>

            <Grid spacing={4}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[0]}
                        label={isEnglish ? formLabelsen[0] : formLabelsfr[0]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.marketSize}
                        helperText={(formik.errors.marketSize && formik.touched.marketSize) ? (formik.errors.marketSize) : null}
                        error={(formik.errors.marketSize && formik.touched.marketSize)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid spacing={6}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[1]}
                        label={isEnglish ? formLabelsen[1] : formLabelsfr[1]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.Competitors}
                        helperText={(formik.errors.Competitors && formik.touched.Competitors) ? (formik.errors.Competitors) : null}
                        error={(formik.errors.Competitors && formik.touched.Competitors)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid spacing={6}>
                <Grid item md={12}>
                    <TextField
                        rows={20}
                        name={inputNames[2]}
                        label={isEnglish ? formLabelsen[2] : formLabelsfr[2]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.comparativeAdvantages}
                        helperText={(formik.errors.comparativeAdvantages && formik.touched.comparativeAdvantages) ? (formik.errors.comparativeAdvantages) : null}
                        error={(formik.errors.comparativeAdvantages && formik.touched.comparativeAdvantages)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>

        </Box>
    );
}

FourthForm.propTypes = {
    formik: PropTypes.object
};
export default FourthForm