import { Button, Container, TextField, } from '@mui/material'
import TwinHeading from 'components/TwinHeading/TwinHeading'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function RegisterUser() {
    const [userIS, setUserIs] = useState()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")

    const navigate = useNavigate();

    const isRequired = true
    function handleSubmit(e) {
        e.preventDefault();
        // console.log(userIS)
        if (userIS === "invest") {
            // location.replace("*/signup")
            navigate('./signup');
        }
        if (userIS === "showcase") {
            navigate('./present_your_project');
            // location.replace("*/present_your_project")
        }
    }
    function onChangeValue(e) {
        // e.preventDefault();
        setUserIs(e.target.value)
        // console.log(e.target.value)
    }
    return (
        <Container>
            <TwinHeading text="Register" />
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }} >
                    <TextField
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        className="textField" required={isRequired} style={{ width: "30vw", margin: "2%", }} label="Name" variant="outlined" size='medium' />
                    <TextField
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        type="email"
                        className="textField" required={isRequired} style={{ width: "30vw", margin: "2%", }} label="Email" variant="outlined" size='medium' />
                    <TextField
                        type="tel"
                        onChange={(e) => setContact(e.target.value)}
                        value={contact}
                        className="textField" required={isRequired} style={{ width: "30vw", margin: "2%", }} label="Contact" variant="outlined" size='medium' />
                    <div onChange={onChangeValue}  >
                        <label>
                            <input type="radio" value="invest" name="userReg" disabled={!contact} />    Investor signUp
                        </label>
                        <br />
                        <label>
                            <input type="radio" value="showcase" name="userReg" disabled={(!contact)} />  Showcase your Projects
                        </label>
                    </div>
                    <div className="buttons" style={{ width: "100%", padding: "0% 3%", display: "flex", alignItems: "center", justifyContent: "end" }} >
                    </div>
                    <Button variant="contained" style={{ marginTop: "12px" }} type="submit">Next</Button>
                </div>
            </form>
        </Container >
    )

}

export default RegisterUser