/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */

import { Box, Grid, TextField, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

function SecondForm(props) {
    const isEnglish = useSelector((state) => state.lang.value);
    const inputNames = [
        "lastName",
        "firstName",
        "role",
        "LinkedIn",
    ]
    const formsLabelsen = [
        "Last name",
        "First name",
        "Role",
        "LinkedIn",
    ]
    const formLabelsfr = [
        "Nom",
        "Prénom",
        "Rôle",
        "Linkedin"
    ]
    const formik = props.formik;
    const heading = props.heading
    return (
        <Box>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                {" "}
                {heading}
            </Typography>

            <Grid container spacing={6}>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[0]}
                        label={isEnglish ? formsLabelsen[0] : formLabelsfr[0]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.lastName}
                        helperText={(formik.errors.lastName && formik.touched.lastName) ? (formik.errors.lastName) : null}
                        error={(formik.errors.lastName && formik.touched.lastName)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[1]}
                        label={isEnglish ? formsLabelsen[1] : formLabelsfr[1]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.firstName}
                        helperText={(formik.errors.firstName && formik.touched.firstName) ? (formik.errors.firstName) : null}
                        error={(formik.errors.firstName && formik.touched.firstName)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[2]}
                        label={isEnglish ? formsLabelsen[2] : formLabelsfr[2]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.role}
                        helperText={(formik.errors.role && formik.touched.role) ? (formik.errors.role) : null}
                        error={(formik.errors.role && formik.touched.role)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        name={inputNames[3]}
                        label={isEnglish ? formsLabelsen[3] : formLabelsfr[3]}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.LinkedIn}
                        helperText={(formik.errors.LinkedIn && formik.touched.LinkedIn) ? (formik.errors.LinkedIn) : null}
                        error={(formik.errors.LinkedIn && formik.touched.LinkedIn)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

SecondForm.propTypes = {
    formik: PropTypes.object
};
export default SecondForm