/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */

import { Typography } from "@mui/material";
import React from "react";
import FileUpload from "react-mui-fileuploader";
import { useSelector } from "react-redux";

function SixthForm(props) {
    const formik = props.formik;


    const isEnglish = useSelector((state) => state.lang.value);

    const handleFileUploadError = (error) => {
        // Do something...
    };

    const handleFilesChange = (files) => {
        // console.log("🚀 ~ file: SixthForm.jsx:16 ~ handleFilesChange ~ files", files)
        // Do something...

    };
    return (
        <>
            <Typography variant='subtitle2'  >{
                isEnglish ? "Upload your documents" : "Chargez vos documents"
            }</Typography>
            <div className=""  >
                <FileUpload
                    multiFile={true}
                    disabled={false}
                    title={
                        isEnglish ? "Do you have any documents to upload? (Pitch Deck, P&L, Forecasts, patents, process, recruitment plan, etc.)"
                            : "Avez-vous des documents à charger ? (Pitch Deck, P&L, Forecasts, brevets, processus, plan de recrutements, etc.)"
                    }
                    header="[Drag to drop]"
                    leftLabel="or"
                    rightLabel="to select files"
                    buttonLabel="click here"
                    buttonRemoveLabel="Remove all"
                    maxFileSize={10}
                    maxUploadFiles={0}
                    maxFilesContainerHeight={357}
                    errorSizeMessage={'fill it or remove it to use the default error message'}
                    allowedExtensions={['pdf', 'png', 'jpg', 'jpeg']}
                    onFilesChange={handleFilesChange}
                    onError={handleFileUploadError}
                    imageSrc={""}
                    bannerProps={{ elevation: 0, variant: "elevation" }}
                    containerProps={{ elevation: 0, variant: "outlined" }}
                    placeholderImageDimension={{
                        xs: { width: 128, height: 128 },
                        sm: { width: 128, height: 128 },
                        md: { width: 164, height: 164 },
                        lg: { width: 256, height: 256 }
                    }}
                />
            </div>
            {/* <div className="" style={{ width: "100%", padding: "5% 5%", gap: "15px", display: "flex", alignItems: "center", justifyContent: "end" }} >
                <Button variant="contained" onClick={() => setBarCounter(barCounter - 1)}  >Back</Button>
                <Button variant="contained" onClick={() => alert("it's done ")} style={{}} >Done</Button>
            </div> */}
        </>
    );
}

export default SixthForm